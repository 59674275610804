
import EditKeyValueLabel from "./EditKeyValueLabel.vue";

export default {
  components: {
    EditKeyValueLabel,
  },

  props: {
    label: {
      type: String,
      default: "",
    },

    labelKey: {
      type: String,
      default: "",
    },
  },
};
